import React from "react";
import './hackathonnav.css'

export const HackathonNav = () => {
    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <h1 className="h1-animation">VEDIC VISION HACKATHON</h1>
            </div>
        </>
    );
};
