import React from "react";
export const Nav=()=>
{
    return(
        <>
        <div className="deptname" style={{display:'flex',justifyContent:"center"}}>
            <img src="../ast-logo.png" className="srkrlogo" alt="logo"/>
            <h1 className="clgdept">AST-TEAM EXAMS</h1>
        </div>
        </>
    )
}